import request from '@/common/axios';

// 违约金规则列表
export function fetchPenaltyRuleList(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2405,
      oReqData: {
        ...data
      }
    }
  });
}

// 修改违约金规则
export function updatePenaltyRule(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2403,
      oReqData: {
        ...data
      }
    }
  });
}


// 违约金列表
export function fetchPenaltyList(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2305,
      oReqData: data
    }
  });
}

// 取消违约
export function penaltyAudit(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 2307,
      "oReqData": {
        "id": data.id,
        "isCheck": data.type
      },
    }
  });
}