<!-- 换电记录 -->
<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="时间" prop="createAt"></el-table-column>
      <el-table-column label="违约原因" prop="ruleId">
        <template #default="scope">
          <eb-badge
            :list="this.const.PENALTY_TYPE"
            :target="scope.row.ruleId"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="用户Id" prop="userId"></el-table-column>
      <el-table-column label="违约金(元)" prop="money">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.money / 100) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="isCheck">
        <template #default="scope">
          <eb-badge
            :list="this.const.AUDIT_STATUS"
            :target="scope.row.isCheck"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="
              handleAudit(scope.row.id, this.const.AUDIT_STATUS_CODE.REJECT)
            "
            >驳回</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pageable"
      layout="prev, pager, next,total"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
// MyBook.vue
import {
  toRefs,
  toRef,
  reactive,
  onMounted,
  isProxy,
  isReactive,
  getCurrentInstance,
} from "vue";
import { fetchPenaltyList, penaltyAudit } from "@/api/penalty";
import { validatenull } from "@/common/validate";

export default {
  props: ["listQuery", "pageable"],
  setup(props, context) {
    let listQuery = toRef(props, "listQuery");
    let { appContext, ctx } = getCurrentInstance();
    let _this = appContext.config.globalProperties;
    const state = reactive({
      dataSource: [],
      listQuery: listQuery,
      total: 0,
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
    });

    // 违约审核
    var handleAudit = (id, type) => {
      let pass = <span class="text-primary">通过</span>;
      let reject = <span class="text-danger">驳回</span>;
      let s = (
        <div>
          是否要 {type === _this.const.AUDIT_STATUS_CODE.PASS ? pass : reject}{" "}
          违约审核?
        </div>
      );
      _this
        .$confirm(s, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          penaltyAudit({ id: id, type: type }).then((res) => {
            _this.$message({
              type: "success",
              message: "操作成功!",
            });

            getList();
          });
        });
    };

    const getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchPenaltyList({ query: state.listQuery, ...state.pages }).then(
        (res) => {
          state.dataSource = res.data.data.records;
          state.total = res.data.data.total;
        }
      );
    };

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
      handleAudit: handleAudit,
    };
  },
};
</script>