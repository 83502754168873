<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <!-- <el-col :span="4">
        <el-input
          v-model="listQuery.agentId"
          placeholder="用户手机号"
        ></el-input>
      </el-col> -->

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <PenaltyRecord
      ref="PenaltyRecord"
      :listQuery="listQuery"
      :pageable="true"
    ></PenaltyRecord>
  </div>
</template>

<script>
import PenaltyRecord from "@/components/view-components/penalty-record";
export default {
  components: { PenaltyRecord },

  data() {
    return {
      listQuery: {
        balanceStatus: -1,
        passTime: -1,
        type: -1,
        userId: -1,
        createAt: "",
        isCheck: -1,
        money: -1,
        vinfo: "",
        deviceUserId: -1,
        ruleId: -1,
        payStatus: -1,
      },
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.$refs["PenaltyRecord"].getList();
    },
  },
};
</script>